import * as React from 'react';

import URI from 'jsuri';
import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import fullStory from 'js/lib/fullStoryUtils';

import Alice from 'bundles/alice/components/Alice';
import { LOGGED_IN_DASHBOARD } from 'bundles/alice/constants/AliceContextType';
import withAliceNotification from 'bundles/alice/lib/withAliceNotification';
import AlicePageviewEvent from 'bundles/alice/models/AlicePageviewEvent';
import GrowthPageExperiments from 'bundles/epic/clients/GrowthPage';
import adsTracker from 'bundles/event-tracking/lib';
import SurveyModal from 'bundles/growth-upsell/user-intent-survey/SurveyModal';
import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import DisableCrawlerIndexing from 'bundles/seo/components/DisableCrawlerIndexing';

import 'css!bundles/unified-home-common/index';

type PropsFromCaller = {
  children?: JSX.Element;
};

type PropsToComponent = PropsFromCaller;

const ConsumerPageWrapper = ({ children }: PropsToComponent) => {
  React.useEffect(() => {
    if (GrowthPageExperiments.get('fullStoryB2CEnabled')) {
      fullStory.init();
      fullStory.set({
        isNewLoggedInHome: true,
      });
    }

    const referrer = new URI(document?.referrer);
    if (referrer.getQueryParamValue('authMode') === 'signup') {
      adsTracker.trackSignup();
    }
  }, []);

  return (
    <React.Fragment>
      {children}
      <Alice />
      <DisableCrawlerIndexing />
      <SurveyModal
        pageType="homePage"
        epicParam="tvInterceptSurveyLate2019Homepage"
        cookieName="post-tv-survey-dec-2019"
        showForCountryCode="US"
      />
      <SurveyModal
        pageType="homePage"
        epicParam="tvInterceptSurveyApr2020GbHomepage"
        cookieName="post-tv-survey-gb-apr-2020"
        showForCountryCode="GB"
      />
    </React.Fragment>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  withGDPR,
  withCCPA,
  withAliceNotification(
    () => {
      return new AlicePageviewEvent({ contextType: LOGGED_IN_DASHBOARD });
    },
    // eslint-disable-next-line camelcase
    () => ({ course_id: 'NO_COURSE_ID' })
  ),
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'dashboard',
      page: 'main',
    },
  }))
)(ConsumerPageWrapper);
