export const MY_LEARNING_QUERY_PARAM = 'myLearningTab';

export const QUERY_PARAM_VALUES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SAVED: 'SAVED',
  SKILLS_DASHBOARD: 'SKILLS_DASHBOARD',
  STARTING_LATER: 'STARTING_LATER',
};

export const PAGE_SIZE_DEFAULT = 8;
