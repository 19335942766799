import * as React from 'react';
import type { ChangeHook } from 'react-router';
import { IndexRoute, Redirect, Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';
import { MY_LEARNING_QUERY_PARAM, QUERY_PARAM_VALUES } from 'bundles/unified-home-common/constants/MyLearningConstants';
import ConsumerPageWrapper from 'bundles/unified-home-common/pages/consumer/ConsumerPageWrapper';

const ConsumerHomePageRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/consumer/home/ConsumerHomePageRoot')
);

const ConsumerMyLearningRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/consumer/my-learning/ConsumerMyLearningRoot')
);

const ConsumerUpdatesNonUnifiedRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/consumer/non-unified/UpdatesNonUnified')
);

const ConsumerAccomplishmentsNonUnifiedRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/consumer/non-unified/AccomplishmentsNonUnified')
);

const baseMyLearningUrl = `/my-learning?${MY_LEARNING_QUERY_PARAM}=`;

const scrollToTopOnNavigate: ChangeHook = (prevState, nextState) => {
  if (nextState.location.action !== 'PUSH') {
    // history navigation
    return;
  }

  if (nextState.location.pathname !== prevState.location.pathname) {
    // always on path navigation
    window.scrollTo(0, 0);
  }
};

export default (
  <CourseraRoute
    onChange={scrollToTopOnNavigate}
    handle={{
      tracking: {
        data: () => ({
          page: { segment: 'consumer' },
        }),
      },
    }}
  >
    <Route path="/" component={ConsumerPageWrapper}>
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp' },
            }),
          },
        }}
      >
        <IndexRoute getComponent={ConsumerHomePageRoot} />
        <Route path="consumer-home" getComponent={ConsumerHomePageRoot} />
        <Route path="/updates" getComponent={ConsumerUpdatesNonUnifiedRoot} />
      </CourseraRoute>
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'accomplishments' },
            }),
          },
        }}
      >
        <Route path="/accomplishments" getComponent={ConsumerAccomplishmentsNonUnifiedRoot} />
      </CourseraRoute>
      <CourseraRoute
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_my_learnings' },
            }),
          },
        }}
      >
        <Route path="/my-learning" getComponent={ConsumerMyLearningRoot} />
        <Route path="consumer-home/my-learning" getComponent={ConsumerMyLearningRoot} />
      </CourseraRoute>
      <Redirect from="/in-progress" to={`${baseMyLearningUrl}${QUERY_PARAM_VALUES.IN_PROGRESS}`} />
      <Redirect from="/saved" to={`${baseMyLearningUrl}${QUERY_PARAM_VALUES.SAVED}`} />
      <Redirect from="/completed" to={`${baseMyLearningUrl}${QUERY_PARAM_VALUES.COMPLETED}`} />
    </Route>
    <Route path="*" component={NotFound} />
  </CourseraRoute>
);
